interface props{

}

export function MyEvent(props:props){
    return(
        <>

        </>
    )
}
