import {IUser} from "../../App";

interface props {
    user: IUser | undefined,
    handleUser: (data: IUser) => void,
}

export function EditEvent(props: props) {
    return (
        <>

        </>
    )
}
